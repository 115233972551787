@font-face {
    font-family: "Turbinado";
    src: url("font/Turbinado.otf") format('opentype');
}

.page_title_header, .homepage_title_header {
    color: #F39A38 !important;
    text-align: center;
    font-family: 'Turbinado', cursive;
    font-weight: 300;
    font-size: 52pt;

}

.page_title_header {
    line-height: 58pt;
}

.homepage_title_header {
    line-height: 35pt;
    margin-top: 50pt;
    margin-bottom: 0;
}

.page_subtitle_header {
    color: #F39A38 !important;
    text-align: center;
    font-size: 15pt;
    font-weight: 300;
    line-height: 15pt;
    margin-top: 0 !important;
}

.image-container {
    display: block;
    width: 100%;
    height: auto;
    position: relative !important;
    overflow: hidden;
    padding: 75% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
}

.container-image {
    display: block;
    width: auto !important;
    height: 100% !important;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.card_header {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.card_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}

.hide-div {
    visibility: hidden !important;
}

ion-toolbar {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
}

.show-index {
    background:  gray;
    color: white;
    margin: 8px;
    padding: 8px;
}

.show-index > ion-label {
    padding:  8px ;
}
.show-index > ion-select {
    padding:  8px ;
}