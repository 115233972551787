.not_seen {
    background: #F39A38 !important;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    position: absolute;
    left: 3px;
    top: calc(50% - 5px)
}

.important {
    color: red !important;
}

.detail_header{
    font-size: 32px;
}